import StrutturaService from "@/services/StruttureService";
import {DateTime} from "luxon";
import {servizi_campi} from "@/services/CampiService";

export interface checkSport {

  'id': number,
  'nome': string,
  'num_giocatori': number[]
  check: boolean,
  borderColor: string,
  prezzo: number,
  durata: number,
  pagamento_persona: boolean
}
export interface checkCampoRules {
  nome: any[],
  descrizione: any[],
  tipo_superfice: any[]
}

export class CampoClass {
  public nome: string
  public descrizione: string
  public media: string[]
  public video: boolean
  public tipo_superfice: number
  public flex: boolean
  public color:string
  public disponibilita:Disponibilita[]
  public servizi_campo:servizi_campi[]

  public constructor() {
    this.nome = ""
    this.descrizione = ""
    this.media = []
    this.video = false
    this.tipo_superfice = 1
    this.flex =false
    this.color = ""
    this.servizi_campo = []
    this.disponibilita=[]
    for(let i = 0; i<7;i++)
    {
      let temp: Disponibilita ={
        giorno_settimana: i+1,
        nome: "",
        orario: "",
        orarioApertura:"",
        orarioChiusura:"",
        endTime:null,
        startTime:null,
        fasce:[],
      };
      this.disponibilita.push(temp);
    }
  }

  public async CalcoloIntervalli(durata:number,i:number){
    let Startime = DateTime.fromISO(this.disponibilita[i].startTime).toFormat("HH:mm");
    let end:string = DateTime.fromISO(this.disponibilita[i].endTime).toFormat("HH:mm");
    let minutiInizio = this.convertH2M(Startime);
    let minutiFine = this.convertH2M(end);
    let result: number = Math.floor((minutiFine-minutiInizio)/durata)
    let arrayIntervalli = [];
    let somma = 0;
    for (let j = 0; j<result;j++)
    {
      somma = minutiInizio+durata;
      arrayIntervalli.push({
        inizio: DateTime.fromMillis(minutiInizio*60000).toUTC().toFormat("HH:mm"),
        fine: DateTime.fromMillis(somma*60000).toUTC().toFormat("HH:mm")
      })
      minutiInizio= somma;
    }
    return arrayIntervalli;
  }

  convertH2M(timeInHour:string){
    let timeParts = timeInHour.split(":");
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
  }
  public async getInfoApertura(giornoScelto:number){
    let oraApertura: string ="";
    let response = await StrutturaService.getStrutture();
    if(response.data[0].struttura.info.orario_apertura)
    {
      oraApertura = response.data[0].struttura.info.orario_apertura[giornoScelto];
    }
    return oraApertura;
  }
  public async getInfoChiusura(giornoScelto:number){
    let oraChiusura: string ="";
    let response = await StrutturaService.getStrutture();
    if(response.data[0].struttura.info.orario_chiusura)
    {
      oraChiusura = response.data[0].struttura.info.orario_chiusura[giornoScelto];
    }
    return oraChiusura;
  }

  public async riempiFasciaOraria(idStruttura:number){

    const response = await StrutturaService.getStruttura(idStruttura);
    if(response.error){
      return;
    }
    const struttura = response.data[0].struttura.info;
    for(let i=0;i<this.disponibilita.length;i++){
      if(!struttura.orario_apertura[i])
        break;
      let data= DateTime.fromISO(struttura.orario_apertura[i]).toUTC()
      const dataChiusura= DateTime.fromISO(struttura.orario_chiusura[i]).toUTC()
      const fasciaOraria= dataChiusura.diff(data,'hours');
      this.disponibilita[i].orarioApertura = DateTime.fromISO(struttura.orario_apertura[i]).toUTC().toFormat("HH:mm")
      this.disponibilita[i].orarioChiusura = DateTime.fromISO(struttura.orario_chiusura[i]).toUTC().toFormat("HH:mm")
      switch (i){
        case 0:
          this.disponibilita[i].nome = "Lunedi";
          break;
        case 1:
          this.disponibilita[i].nome = "Martedi";
          break;
        case 2:
          this.disponibilita[i].nome = "Mercoledi";
          break;
        case 3:
          this.disponibilita[i].nome = "Giovedi";
          break;
        case 4:
          this.disponibilita[i].nome = "Venerdi";
          break;
        case 5:
          this.disponibilita[i].nome = "Sabato";
          break;
        case 6:
          this.disponibilita[i].nome = "Domenica";
          break;
        default:
          break;
      }

    }

  }
}


export interface Disponibilita {

  'giorno_settimana':number,
  'nome':string,
  'orario':string
  'orarioApertura':string
  'orarioChiusura':string
  'startTime':any
  'endTime':any
  'fasce':Fasce[]
}

 interface Fasce{
 intervalli:{inizio:string,fine:string}[]
   inizio:string;
   fine:string;
 }
